@import '../../variables';

.alertContent {
    width: calc(100% - 2.5em);
}

.refreshBtn {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
